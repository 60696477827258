import React from 'react';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import { string, node } from 'prop-types';
import Typography from '@bit/medicalwebexperts.mwe-ui.typography';
import Image from '@bit/medicalwebexperts.mwe-ui.image';
import Link from '../../Link/Link';

const propTypes = {
  date: string,
  description: string.isRequired,
  image: node,
  link: string.isRequired,
  title: string.isRequired,
  alt: string,
};

const defaultProps = {
  date: null,
  image: null,
  alt: null,
};

const Box = styled(Link)(
  css({
    ':hover': {
      textDecoration: 'none',
    },
    boxSizing: 'border-box',
    position: 'relative',
    textDecoration: 'none',
    py: 1,
    px: 2,
    display: 'block',
  }),
);

const Title = styled(Typography)(
  css({
    fontSize: 'lg',
    ':hover': {
      textDecoration: 'underline',
    },
  }),
);

const Description = styled.div(
  css({
    display: 'flex',
    alignItems: 'center',
  }),
);

const LinkBox = ({ date, description, image, link, title, alt }) => (
  <Box to={link}>
    <Title variant="h3">{title}</Title>
    <Description>
      {image && <Image src={image} mr={4} alt={alt} />}
      <Typography
        variant="body2"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </Description>
    <Typography variant="caption">{date}</Typography>
  </Box>
);

LinkBox.propTypes = propTypes;
LinkBox.defaultProps = defaultProps;

export default LinkBox;
