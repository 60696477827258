import React from 'react';
import css from '@styled-system/css';
import styled from '@emotion/styled';
import Container from '@bit/medicalwebexperts.mwe-ui.container';
import Typography from '@bit/medicalwebexperts.mwe-ui.typography';
import Flex from '@bit/medicalwebexperts.mwe-ui.flex';
import Image from '@bit/medicalwebexperts.mwe-ui.image';
import Carousel from '../../Carousel/Carousel';
import Button from '../../Button/Button';
import Link from '../../Link/Link';
import word from '../../../images/patients-word-carousel.jpg';
import egg from '../../../images/egg-freezing-carousel.jpg';
import secondOpinion from '../../../images/second-opinion-carousel.jpg';
import freeScreening from '../../../images/get-a-free-online-fertility-screening.jpg';

const CarouselItem = styled(Flex)(
  css({
    my: 10,
    pr: ['0', null, 5],
    flexDirection: ['column', null, 'row'],
    alignItems: 'center',
  }),
);

const CarouselImage = styled(Flex.Box)(
  css({
    mr: 10,
    ml: 5,
    width: '390px',
    display: ['none', null, 'block'],
  }),
);

const MainCarousel = () => (
  <Container mb={10}>
    <Carousel
      naturalSlideWidth={1170}
      naturalSlideHeight={310}
      totalSlides={5}
      interval={10000}
      infinite
      isIntrinsicHeight
      isPlaying
    >
      <Carousel.Slide index={0}>
        <Flex my={5} px={5} flexDirection={['column', null, 'row']}>
          <Flex.Box flex="1" textAlign={['center', null, 'left']}>
            <Typography variant="h3">
              We are hiring a board-eligible/certified reproductive
              endocrinologist!
            </Typography>
            <Typography paragraph>
              CHR is seeking a qualified physician with proven research interest
              to join the center’s team of clinicians and biologists.
              Commensurate with qualifications, appointment are available at
              junior, assistant, associate and senior scientist levels.
            </Typography>
            <Typography paragraph>
              A leading private, academically affiliated fertility center in New
              York City with significant research activities and worldwide
              patient clientele, CHR offers competitive salaries and benefits,
              coupled with strong incentives linked to research efforts and
              publication success. In combination with the freedom of a private
              practice set-up, this position offers a unique opportunity for
              individuals interested in exploration of the unknown and in
              pursuit of discoveries suitable to quick translation into clinical
              practice. The position is available immediately.
            </Typography>
            <Typography>
              <strong>
                Please submit CVs to Ms. Jolanta Tapper at jtapper@thechr.com
              </strong>
            </Typography>
          </Flex.Box>
        </Flex>
      </Carousel.Slide>
      <Carousel.Slide index={1}>
        <CarouselItem>
          <CarouselImage>
            <Image src={word} responsive maxWidth="390px" />
          </CarouselImage>
          <Flex.Box flex="1" textAlign={['center', null, 'left']}>
            <Typography variant="h3">
              Our Patients Come from All Over the World
            </Typography>
            <Typography paragraph>
              Over half our patients seek our expertise long-distance. We are
              experienced in long-distance fertility care.
            </Typography>
            <Button as={Link} to="/services/int/" variantColor="primary">
              Learn More
            </Button>
          </Flex.Box>
        </CarouselItem>
      </Carousel.Slide>
      <Carousel.Slide index={2}>
        <CarouselItem>
          <CarouselImage>
            <Image src={egg} responsive maxWidth="390px" />
          </CarouselImage>
          <Flex.Box flex="1" textAlign={['center', null, 'left']}>
            <Typography variant="h3">Is Egg Freezing for You?</Typography>
            <Typography paragraph>
              A new, affordable egg freezing program from one of the
              world&apos;s top fertility centers.
            </Typography>
            <Button
              as={Link}
              to="/services/fertility-preservation/egg-freezing/"
              variantColor="primary"
            >
              Learn More
            </Button>
          </Flex.Box>
        </CarouselItem>
      </Carousel.Slide>
      <Carousel.Slide index={3}>
        <CarouselItem>
          <CarouselImage>
            <Image src={secondOpinion} responsive maxWidth="390px" />
          </CarouselImage>
          <Flex.Box flex="1" textAlign={['center', null, 'left']}>
            <Typography variant="h3">Second Opinion Program</Typography>
            <Typography paragraph>
              To help you evaluate your options, we offer online consultations
              with our expert REIs.
            </Typography>
            <Button
              as={Link}
              to="/getstarted/second-opinion"
              variantColor="primary"
            >
              SEE HOW THE PROGRAM WORKS
            </Button>
          </Flex.Box>
        </CarouselItem>
      </Carousel.Slide>
      <Carousel.Slide index={4}>
        <CarouselItem>
          <CarouselImage>
            <Image src={freeScreening} responsive maxWidth="390px" />
          </CarouselImage>
          <Flex.Box flex="1" textAlign={['center', null, 'left']}>
            <Typography variant="h3">
              Get a Free, Online Fertility Screening - No Appointment Necessary
            </Typography>
            <Typography paragraph>
              Developed by CHR physicians, What’s My Fertility is a free online
              screening tool that predicts your risk of infertility in your
              30’s. You’ll learn your risk of developing{' '}
              <Link to="/dor/premature-ovarian-aging/">
                premature ovarian aging
              </Link>
              , a silent cause of infertility that affects 1 in 10 women.
              Perfect for all women ages 18-35.
            </Typography>
            <Button
              as={Link}
              to="/services/whatsmyfertility/"
              variantColor="primary"
            >
              Learn More About What’s My Fertility
            </Button>
          </Flex.Box>
        </CarouselItem>
      </Carousel.Slide>
    </Carousel>
  </Container>
);

export default MainCarousel;
