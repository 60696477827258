import React from 'react';
import css from '@styled-system/css';
import styled from '@emotion/styled';
import Container from '@bit/medicalwebexperts.mwe-ui.container';
import Typography from '@bit/medicalwebexperts.mwe-ui.typography';
import Flex from '@bit/medicalwebexperts.mwe-ui.flex';
import Image from '@bit/medicalwebexperts.mwe-ui.image';
import Carousel from '../../Carousel/Carousel';
import Button from '../../Button/Button';
import Link from '../../Link/Link';
import testimonial1 from '../../../images/testimonial-1.jpg';
import testimonial2 from '../../../images/testimonial-2.jpg';

const TestimonialImage = styled.div(
  css({
    size: '200px',
    borderRadius: 'full',
    overflow: 'hidden',
    mr: 10,
    display: ['none', null, 'block'],
  }),
);

const TestimonialQuote = styled(Typography)(
  css({
    fontSize: 'xl',
    fontFamily: 'heading',
    fontStyle: 'italic',
    color: 'gray.600',
    lineHeight: 'shorter',
    textAlign: ['center', null, 'left'],
  }),
);

const TestimonialAuthor = styled(TestimonialQuote)(
  css({
    mt: 2,
    fontSize: 'md',
    color: 'black',
    fontWeight: 'bold',
  }),
);

const Testimonials = () => (
  <Container mb={10}>
    <Typography variant="subtitle1">Testimonials</Typography>
    <Carousel
      naturalSlideWidth={1170}
      naturalSlideHeight={240}
      totalSlides={2}
      interval={10000}
      infinite
      isIntrinsicHeight
      isPlaying
    >
      <Carousel.Slide index={0}>
        <Flex alignItems="center" flexDirection={['column', null, 'row']} p={5}>
          <Flex.Box>
            <TestimonialImage>
              <Image src={testimonial1} responsive />
            </TestimonialImage>
          </Flex.Box>
          <Flex.Box flex="1">
            <TestimonialQuote>
              Thank you for taking me on as a patient when other doctors told me
              my dream of being a mother was impossible. I also want to thank
              you for the miracle of my son. I know that God led me to you and
              your excellent staff at a time when I had very little hope, after
              every other doctor discouraged me. There really are no adequate
              words to express my gratitude to you, and Dorota, who was so
              caring.
            </TestimonialQuote>
            <TestimonialAuthor>O.C. from Michigan</TestimonialAuthor>
          </Flex.Box>
        </Flex>
      </Carousel.Slide>
      <Carousel.Slide index={0}>
        <Flex alignItems="center" flexDirection={['column', null, 'row']} p={5}>
          <Flex.Box>
            <TestimonialImage>
              <Image src={testimonial2} responsive />
            </TestimonialImage>
          </Flex.Box>
          <Flex.Box flex="1">
            <TestimonialQuote>
              My little boy was born right after the new year, weighing almost 9
              pounds and just under 20 inches long. He is beautiful, very alert
              and curious to find out more about the world he lives in now.
              Thank you and the whole team at CHR, especially Dr. Gleicher and
              Dr. Kushnir, so much for making my dream come true. I will never
              forget the moment when I first heard his heartbeat on the
              ultrasound, it was a miracle for me and this miracle will be 7
              weeks old tomorrow. I cannot describe how grateful I am, than you
              all again so much, you made this possible and I am very, every
              happy!
            </TestimonialQuote>
            <TestimonialAuthor>C.S.</TestimonialAuthor>
          </Flex.Box>
        </Flex>
      </Carousel.Slide>
    </Carousel>
    <Flex justifyContent="center" mt={8}>
      <Flex.Box>
        <Button
          as={Link}
          to="/about/success/stories-all/"
          variantColor="primary"
        >
          See More Success Stories
        </Button>
      </Flex.Box>
    </Flex>
  </Container>
);

export default Testimonials;
