import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import css from '@styled-system/css';
import styled from '@emotion/styled';
import Form from '@bit/medicalwebexperts.mwe-ui.form';
import Button from '../../Button/Button';
import Link from '../../Link/Link';

const Wrapper = styled.div(css({ mb: 4 }));

const options = [
  { label: 'AMH', value: 'amh' },
  { label: 'Autoimmunity & Infertility', value: 'autoimmunity-infertility' },
  { label: 'Becoming a Patient', value: 'becoming-a-patient' },
  { label: 'CHR', value: 'chr' },
  { label: 'DHEA', value: 'dhea' },
  { label: 'Diminished Ovarian Reserve', value: 'diminished-ovarian-reserve' },
  { label: 'Egg & Embryo Donation', value: 'egg-embryo-donation' },
  { label: 'Egg & Embryo Quality', value: 'eggembryo-quality' },
  { label: 'Endometriosis', value: 'endometriosis' },
  {
    label: 'Fertility Preservation & Egg Freezing',
    value: 'fertility-preservation',
  },
  { label: 'FSH', value: 'fsh' },
  { label: 'Gender Selection', value: 'gender-selection' },
  { label: 'IVF', value: 'ivf' },
  { label: 'Male Infertility', value: 'male-infertility' },
  { label: 'Patient Testimonial', value: 'patient-testimonial' },
  { label: 'PCOS', value: 'pcos' },
  { label: 'PGD/PGS', value: 'pgdpgs' },
  { label: 'Pregnancy after 40', value: 'pregnancy-after-40' },
  { label: 'Premature Ovarian Aging (POA)', value: 'poa' },
  { label: 'Premature Ovarian Failure (POF)', value: 'pof' },
  { label: 'Repeated Miscarriages', value: 'repeated-miscarriages' },
  { label: 'Research & Clinical Trials', value: 'research-clinical-trials' },
  { label: 'Testing & Diagnosis', value: 'testing-diagnosis' },
  { label: 'Unexplained infertility', value: 'unexplained-infertility' },
];

const VideoGallery = () => {
  const {
    site: { siteMetadata },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            videoGalleryURL
          }
        }
      }
    `,
  );

  const [category, setCategory] = useState(siteMetadata.videoGalleryURL);
  const handleChange = ({ value }) => {
    setCategory(`${siteMetadata.videoGalleryURL}/category/${value}/`);
  };

  return (
    <>
      <Wrapper>
        <Form.Select
          size="lg"
          options={options}
          isSearchable={false}
          onChange={handleChange}
          placeholder="Choose a Topic"
          aria-label="Choose a Topic"
        />
      </Wrapper>
      <Button as={Link} to={category} target="_blank" variantColor="primary">
        Watch the Videos
      </Button>
    </>
  );
};

export default VideoGallery;
