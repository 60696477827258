import React, { Fragment } from 'react';
import css from '@styled-system/css';
import styled from '@emotion/styled';
import { useStaticQuery, graphql } from 'gatsby';
import Flex from '@bit/medicalwebexperts.mwe-ui.flex';
import Typography from '@bit/medicalwebexperts.mwe-ui.typography';
import Divider from '@bit/medicalwebexperts.mwe-ui.divider';
import wmfLogo from '../../../images/wmf-logo.png';
import LinkBox from './LinkBox';

const Articles = styled(Flex.Box)(
  css({
    width: ['100%', null, null, 'sidebar'],
  }),
);

const ArticleDivider = styled(Divider)(
  css({
    borderColor: 'gray.400',
    width: '95%',
    mx: 'auto',

    '&:last-of-type': {
      display: 'none',
    },
  }),
);

const Bordered = styled(Flex.Box)(({ theme }) =>
  css({
    borderLeft: 'none',
    borderRight: 'none',
    [theme.mq.md]: {
      borderLeft: '1px',
      borderRight: '1px',
      borderColor: 'gray.200',
    },
  }),
);

const Treatments = () => {
  const { allWordpressPost } = useStaticQuery(
    graphql`
      query {
        allWordpressPost(filter: { categories: { eq: 208 } }, limit: 5) {
          edges {
            node {
              id
              path
              title
              content
              date
            }
          }
        }
      }
    `,
  );

  return (
    <Flex flexDirection={['column', null, null, 'row']}>
      <Flex.Box flex="1" pr={8}>
        <Typography variant="subtitle1" as="h3">
          Treatments & Conditions
        </Typography>
        <Flex flexDirection={['column', null, 'row']}>
          <Flex.Box flex={1}>
            <LinkBox
              link="/services/infertility-treatments/in-vitro-fertilization/"
              title="What is IVF?"
              description="We fight for every egg and every embryo. Our success rates are among the best in the world."
            />
          </Flex.Box>
          <Bordered flex={1}>
            <LinkBox
              link="/egg-donation/"
              title="Overcoming Failed IVF"
              description="CHR specializes in IVF for women who have had multiple failed cycles elsewhere."
            />
          </Bordered>
          <Flex.Box flex={1}>
            <LinkBox
              link="/dor/premature-ovarian-aging/"
              title="“Poor Response” to IVF Stimulation"
              description="Individualized protocols make IVF successful for women who have been diagnosed as “poor responders.”"
            />
          </Flex.Box>
        </Flex>
        <Divider />
        <Flex flexDirection={['column', null, 'row']}>
          <Flex.Box flex={1}>
            <LinkBox
              link="/dor/high-fsh/"
              title="High FSH"
              description="CHR has developed age-specific FSH levels, which make treatment more individualized."
            />
          </Flex.Box>
          <Bordered flex={1}>
            <LinkBox
              link="/dor/amh-levels/"
              title="AMH and FSH"
              description="Age-specific FSH and AMH levels indicate whether a woman’s ovarian reserve is normal for her age."
            />
          </Bordered>
          <Flex.Box flex={1}>
            <LinkBox
              link="/infertilityedu/causes/anti-mullerian-hormone/program/"
              title="Treatment for Low AMH"
              description="CHR has developed age-specific AMH levels, which make treatment more individualized."
            />
          </Flex.Box>
        </Flex>
        <Divider />
        <Flex flexDirection={['column', null, 'row']}>
          <Flex.Box flex={1}>
            <LinkBox
              link="/egg-donation/"
              title="Egg Donation"
              description="World’s most diverse egg donor pool with 200 egg donors ready to be matched, and EcoDEP, a frozen donor egg program."
            />
          </Flex.Box>
          <Bordered flex={1}>
            <LinkBox
              link="/egg-donation/donating-eggs-faqs/"
              title="FAQ for Egg Donors"
              description="Frequently asked questions from women interested in donating eggs."
            />
          </Bordered>
          <Flex.Box flex={1}>
            <LinkBox
              link="/egg-donation/become-an-egg-donor/"
              title="Become an Egg Donor"
              description="Interested in helping a couple conceive? Learn more about our program."
            />
          </Flex.Box>
        </Flex>
        <Divider />
        <Flex flexDirection={['column', null, 'row']}>
          <Flex.Box flex={1}>
            <LinkBox
              link="/infertilityedu/causes/getting-pregnant-after-40/"
              title="Pregnancy after 40"
              description="A majority of our patients are over 40. We have the expertise, and continue to refine our approach through research."
            />
          </Flex.Box>
          <Bordered flex={1}>
            <LinkBox
              link="/services/infertility-treatments/getting-pregnant-with-own-eggs/"
              title="Getting Pregnant with Your Own Eggs"
              description="Women come to CHR after being told egg donation was their only option. 1/3 of them conceive, using their own eggs."
            />
          </Bordered>
          <Flex.Box flex={1}>
            <LinkBox
              link="/dor/improve-egg-quality/"
              title="Improving Egg Quality"
              description="The most important factor in treating DOR is a timely diagnosis."
            />
          </Flex.Box>
        </Flex>
        <Divider />
        <Flex flexDirection={['column', null, 'row']}>
          <Flex.Box flex={1}>
            <LinkBox
              link="/dor/diminished-ovarian-reserve-treatment/"
              title="Diminished Ovarian Reserve Treatment"
              description="With intense focus on individuality of each patient, we have doubled pregnancy rates for DOR patients."
            />
          </Flex.Box>
          <Bordered flex={1}>
            <LinkBox
              link="/dor/dhea-what-is-dehydroepiandrosterone/"
              title="DHEA for DOR"
              description="CHR pioneered the use of DHEA in IVF, which has been shown to significantly increase pregnancy chances."
            />
          </Bordered>
          <Flex.Box flex={1}>
            <LinkBox
              link="/dor/premature-ovarian-aging/"
              title="Premature Ovarian Aging"
              description="Unique expertise in helping women with “old ovaries” to conceive with use of their own eggs."
            />
          </Flex.Box>
        </Flex>
        <Divider />
        <Flex flexDirection={['column', null, 'row']}>
          <Flex.Box flex={1}>
            <LinkBox
              link="/infertilityedu/causes/ovulatorydysfunction/"
              title="Ovulatory Dysfunction"
              description="Ovulatory problems account for 20 to 30% of infertility cases."
            />
          </Flex.Box>
          <Bordered flex={1}>
            <LinkBox
              link="/infertilityedu/causes/autoimmunedisease/"
              title="Autoimmunity &amp; Infertility"
              description="Fertility treatments and miscarriage go hand-in-hand in autoimmunity-related infertility."
            />
          </Bordered>
          <Flex.Box flex={1}>
            <LinkBox
              link="/infertilityedu/causes/repeatedmiscarriages/"
              title="Repeated Miscarriages"
              description="When faced with repeated miscarriages, finding the cause is the first step toward successful pregnancy and delivery."
            />
          </Flex.Box>
        </Flex>
        <Divider />
        <Flex flexDirection={['column', null, 'row']}>
          <Flex.Box flex={1}>
            <LinkBox
              link="/services/infertility-treatments/genderselection/"
              title="Gender Selection"
              description="In-house PGD ensures accuracy of gender selection and allows for screening for hundreds of genetic diseases."
            />
          </Flex.Box>
          <Bordered flex={1}>
            <LinkBox
              link="/services/discountprograms/ecolvf/"
              title="EcoIVF (Mini-IVF)"
              description="A low-cost, low-intensity form of IVF designed for women under age 38 with normal ovarian reserve."
            />
          </Bordered>
          <Flex.Box flex={1}>
            <LinkBox
              link="/about/clinicaltrials/"
              title="Infertility Clinical Trials"
              description="CHR performs clinical trials with one goal: applying new discoveries toward improving the care of our patients."
            />
          </Flex.Box>
        </Flex>
        <Divider />
        <Flex flexDirection={['column', null, 'row']}>
          <Flex.Box flex={1}>
            <LinkBox
              link="/dor/fsh-what-it-is/"
              title="What is FSH?"
              description="About follicle stimulating hormone - what it is, and its role in reproduction."
            />
          </Flex.Box>
          <Bordered flex={1}>
            <LinkBox
              link="/dor/coq10/"
              title="What is CoQ10 (Coenzyme Q10)?"
              description="CoQ10 protects mitochondria from damage and helps cells metabolize energy, which improves egg quality."
            />
          </Bordered>
          <Flex.Box flex={1}>
            <LinkBox
              link="/dor/ovarian-reserve-testing/"
              title="Ovarian Reserve Testing"
              description="Effective treatment for DOR begins with a timely diagnosis, as ovarian reserve and egg quality will only continue to decline."
            />
          </Flex.Box>
        </Flex>
      </Flex.Box>
      <Articles>
        <Typography
          variant="subtitle1"
          as="h3"
          display={['none', null, null, 'block']}
        >
          Fertility Updates
        </Typography>
        <Flex
          flexDirection="column"
          bg="gray.300"
          py={3}
          display={['none', null, null, 'block']}
        >
          {allWordpressPost.edges.map(({ node: post }) => {
            const title = post.title.replace(/(<([^>]+)>)/gi, '');
            const content = post.content
              .replace(/(<([^>]+)>)/gi, '')
              .replace('… Read more', '');

            return (
              <Fragment key={post.id}>
                <Flex.Box>
                  <LinkBox
                    link={post.path}
                    title={
                      title.length > 40 ? `${title.slice(0, 37)}...` : title
                    }
                    description={
                      content.length > 140
                        ? `${content.slice(0, 140)}...`
                        : content
                    }
                    date={`${new Date(post.date).toDateString()}`}
                  />
                </Flex.Box>
                <ArticleDivider />
              </Fragment>
            );
          })}
        </Flex>
        <Flex flexDirection="column" bg="gray.300" my={3} py={3}>
          <Flex.Box>
            <LinkBox
              alt="WMF"
              link="/services/whatsmyfertility/"
              title="What’s My Fertility at CHR"
              description="Innovative prospective risk screening for premature ovarian aging for young women."
              image={wmfLogo}
            />
          </Flex.Box>
        </Flex>
      </Articles>
    </Flex>
  );
};

export default Treatments;
