/* eslint-disable react/prop-types */
import React from 'react';
import css from '@styled-system/css';
import styled from '@emotion/styled';
import Container from '@bit/medicalwebexperts.mwe-ui.container';
import Typography from '@bit/medicalwebexperts.mwe-ui.typography';
import Flex from '@bit/medicalwebexperts.mwe-ui.flex';
import Card from '@bit/medicalwebexperts.mwe-ui.card';
import Image from '@bit/medicalwebexperts.mwe-ui.image';
import SEO from '../components/SEO/SEO';
import Button from '../components/Button/Button';
import YoutubeVideo from '../components/YoutubeVideo/YoutubeVideo';
import Treatments from '../components/Home/Treatments/Treatments';
import Link from '../components/Link/Link';
import VideoGallery from '../components/Home/VideoGallery/VideoGallery';
import Testimonials from '../components/Home/Testimonials/Testimonials';
import MainCarousel from '../components/Home/MainCarousel/MainCarousel';
import ContactUs from '../components/Internal/ContactUs/ContactUs';
import covidIcon from '../images/covid-icon.svg';
import videoIcon from '../images/video-gallery-icon.svg';
import voiceIcon from '../images/voice-icon.svg';
import video1 from '../images/video1.jpg';
import video2 from '../images/video2.jpg';
import video3 from '../images/video3.jpg';
import video4 from '../images/video4.jpg';
import videoGallery from '../images/home-card-video-gallery.jpg';
import chrMedia from '../images/chr-media.png';
import congrats from '../images/chr-congratulation.jpg';
import becomePatient from '../images/become-patient-banner.jpg';

const Sidebar = styled(Flex.Box)(
  css({
    width: ['100%', null, null, 'sidebar'],
  }),
);

const FullWidth = styled.section(
  css({
    px: 4,
    py: 16,
    bg: 'gray.200',
    mb: 10,
  }),
);

const AccessCardWrapper = styled(Flex.Box)(
  css({
    display: 'flex',
  }),
);

const AccessCard = styled(Card)(
  css({
    ':hover': {
      textDecoration: 'none',
    },
    overflow: 'hidden',
    textDecoration: 'none',
    display: 'block',
    width: ['auto', null, '390px'],
    maxWidth: ['350px', null, 'auto'],
  }),
);

const CardIcon = styled(Flex)(
  css({
    width: 20,
    height: 20,
    bg: 'white',
    borderRadius: 'full',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    zIndex: 'docked',
    top: '-60px',
    left: '50%',
    mb: '-60px',
    transform: 'translateX(-50%)',
  }),
);

const PrimaryColor = styled.span(
  css({
    color: 'primary.main',
  }),
);

const IndexPage = () => (
  <>
    <SEO
      title="Egg Donor, Infertility &amp; IVF experts in New York, NY"
      path="/"
    />
    {/* Hero & Videos */}
    <Container my={8}>
      <Flex
        justifyContent="space-between"
        flexDirection={['column', null, null, 'row']}
      >
        <Flex.Box flex={1} pr={['0', null, null, 8]}>
          <Typography variant="h1">
            We take on the toughest infertility cases.
          </Typography>
          <Typography variant="h4">
            New York City&apos;s CHR is the fertility center the whole world
            turns to. We fight for every egg and embryo.
          </Typography>
          <Flex flexDirection={['column', null, null, 'row']}>
            <Flex.Box flex={1} pr={[0, null, null, 4]}>
              <YoutubeVideo
                url="https://www.youtube.com/watch?v=VPPcKU_QUkE"
                playing
                light={video1}
              />
            </Flex.Box>
            <Sidebar>
              <Typography variant="h4">
                Over the past 33 years, the Center for Human Reproduction has
                gained a worldwide reputation as the fertility center of last
                resort. When women have tried everything, and when everything
                else has failed, they come to us. And regardless of the
                difficulty of their infertility issues, we work with them.
              </Typography>
            </Sidebar>
          </Flex>
        </Flex.Box>
        <Sidebar display={['none', null, null, 'block']}>
          <Typography variant="subtitle1" as="h3">
            Recent Videos
          </Typography>
          <YoutubeVideo
            url="https://www.youtube.com/watch?v=4xcGZzXTtmQ"
            ratioProps={{ mb: 5 }}
            playing
            light={video4}
          />
          <YoutubeVideo
            url="https://www.youtube.com/watch?v=B9tqXH7ilts"
            playing
            light={video3}
          />
        </Sidebar>
      </Flex>
    </Container>

    {/* Full width access section */}
    <FullWidth>
      <Container>
        <Flex flexDirection={['column', null, 'row']} alignItems="center">
          <AccessCardWrapper>
            <AccessCard as={Link} to="/video-gallery/">
              <Image src={videoGallery} responsive alt="CHR Video Gallery" />
              <Card.Body textAlign="center">
                <CardIcon>
                  <Image src={videoIcon} alt="CHR Video" />
                </CardIcon>
                <Typography variant="h3">Video Gallery</Typography>
                <Typography variant="body2">
                  Watch over 200 videos from our world-renowned physicians.
                </Typography>
              </Card.Body>
            </AccessCard>
          </AccessCardWrapper>
          <AccessCardWrapper mx={[0, null, 8]} my={[8, null, 0]}>
            <AccessCard as={Link} to="/fertility/medium/voice/">
              <Image src={chrMedia} responsive alt="CHR Media" width="100%" />
              <Card.Body textAlign="center">
                <CardIcon>
                  <Image src={voiceIcon} alt="CHR Voice" />
                </CardIcon>
                <Typography variant="h3">CHR VOICE</Typography>
                <Typography variant="body2">
                  CHR&apos;s monthly update on research, new treatments, & more.
                </Typography>
              </Card.Body>
            </AccessCard>
          </AccessCardWrapper>
          <AccessCardWrapper>
            <AccessCard
              as={Link}
              to="/fertility/dr-yangs-abstract-wins-srbt-basic-science-award/"
            >
              <Image src={congrats} responsive alt="CHR" width="100%" />
              <Card.Body textAlign="center">
                <CardIcon>
                  <Image src={covidIcon} alt="CHR Covid-19" />
                </CardIcon>
                <Typography variant="h3">Congratulations!</Typography>
                <Typography variant="body2">
                  Dr. Yang&apos;s abstract on self-correction of embryos won
                  SRBT Basic Science Award.
                </Typography>
              </Card.Body>
            </AccessCard>
          </AccessCardWrapper>
        </Flex>
      </Container>
    </FullWidth>

    {/* Main Carousel */}
    <MainCarousel />

    {/* Treatments & Conditions */}
    <Container mb={10}>
      <Treatments />
    </Container>

    {/* Testimonial Carousel */}
    <Testimonials />

    {/* Video Gallery Access */}
    <Container mb={10} display={['none', null, null, 'block']}>
      <Typography variant="subtitle1" as="h3">
        Visit Our Video Gallery and Get the Experts&apos; Opinion
      </Typography>
      <Flex>
        <Flex.Box flex={1} pr={5}>
          <Typography variant="h3" my={5}>
            <strong>
              <PrimaryColor>200+</PrimaryColor> videos on{' '}
              <PrimaryColor>20</PrimaryColor> different topics.
            </strong>
          </Typography>
          <Typography paragraph>
            We&apos;ve put our expansive fertility knowledge online for anyone
            to access. Watch our hundreds of videos on infertility and how we
            treat it at CHR.
          </Typography>
          <VideoGallery />
        </Flex.Box>
        <Flex.Box width="680px">
          <YoutubeVideo
            url="https://www.youtube.com/watch?v=9aqCne3MtwA"
            light={video2}
            playing
          />
        </Flex.Box>
      </Flex>
    </Container>

    {/* Banner */}
    <Container mb={8}>
      <Flex bg="gray.200" flexDirection={['column', null, 'row']}>
        <Flex.Box width={['100%', null, '390px']}>
          <Image src={becomePatient} responsive />
        </Flex.Box>
        <Flex.Box flex={1} px={[4, null, 8]} py={8}>
          <Typography variant="h3" mb={2} fontSize="3xl">
            18,300 Babies and Counting
          </Typography>
          <Typography mb={2}>
            Over the past 3 decades, CHR&apos;s research-driven infertility
            treatments have helped thousands overcome infertility.
          </Typography>
          <Button as={Link} to="/getstarted/" variantColor="primary">
            Become a Patient
          </Button>
        </Flex.Box>
      </Flex>
    </Container>
    <ContactUs />
  </>
);

export default IndexPage;
